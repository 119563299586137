<template>
	<div class="wrap">
		<el-page-header @back="$router.go(-1)" content="提现" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="form_box">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="提现金额" prop="withdrawal_money" :rules="rules.required">
					<el-input-number v-model="form.withdrawal_money" :min="1" :max="99999" :precision="2">
					</el-input-number>
					<span>实际到账金额为:<span
							style="color: red">{{parseFloat(form.withdrawal_money-form.withdrawal_money*0.006)}}</span></span>
				</el-form-item>

				<!-- 获取昵称 -->
				<!-- <el-form-item label="项目名称" prop="server_name" :rules="rules.required">
					<el-input v-model="form.server_name"></el-input>
					
				</el-form-item> -->

				<el-form-item label="搜索昵称">
					<!-- 	<el-select v-model="form.account_id" placeholder="请选择" @change="accountChange">
						<el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id">
						</el-option>
					</el-select> -->
					<div style="display: flex; flex-direction: row;">
						<el-input v-model="nickname" style="width: 217px;"></el-input>
						<el-button @click="search" style="margin-left: 5px;">搜索</el-button>
					</div>
				</el-form-item>


				<div class="result-container" v-if="producList.length!=''" style="margin-bottom: 10px;">
					<el-table :data="producList" border>
						<el-table-column width="60px" align="center">
							
							<template v-slot="props">
								<el-radio v-model="selectId" :label="props.row.id" @change="handleRowChange(props.row)">
									{{""}}
								</el-radio>
							</template>
						</el-table-column>
						<el-table-column prop="picture" label="头像" align="center">
							<template slot-scope="scope">
								<img :src="scope.row.avatar" min-width="70" height="70" />
							</template>
						</el-table-column>

						<el-table-column label="昵称" prop="nickname"></el-table-column>

					</el-table>
				</div>

				<el-form-item label="真实姓名">
					<el-input v-model="real_name" style="width: 217px;"></el-input>
				</el-form-item>


				<!-- 	<el-form-item label="提现账户" prop="account_id" :rules="rules.required">
					<el-select v-model="form.account_id" placeholder="请选择" @change="accountChange">
						<el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id">
						</el-option>
					</el-select>
					<el-button @click="$router.push('/account/bankcard')">提现账户管理</el-button>
				</el-form-item> -->


				<!-- <el-form-item label="提现帐号" prop="account">
					{{curAccount.account_number||'请选择提现账户'}}
				</el-form-item> -->
				<!-- <el-form-item label="账户姓名" prop="accountName">
					{{curAccount.account_name||'请选择提现账户'}}
				</el-form-item> -->
				<!-- <el-form-item label="手机号" prop="phone">
					{{curAccount.phone||'请选择提现账户'}}
				</el-form-item> -->
				<!-- <el-form-item label="到账时间">
					预计3个工作日内到账
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="onSubmit">提交</el-button>
					<el-button @click="$router.go(-1)">取消</el-button>
				</el-form-item>
			</el-form>

		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../common/common.rules";
	export default {
		data() {
			return {
				rules,
				form: {
					withdrawal_money: 1,
					account_id: '',
				},
				nickname: "",
				name: "",
				curAccount: {},
				options: [],
				producList: [],
				selectId: '',
				real_name:""
			};
		},
		mounted() {
			this.$api.store.getAccounts().then(res => {
				res.data.data.forEach(item => {
					item.label = item.account_name + '(' + item.account_number.substr(item.account_number
						.length - 4) + ')';
				})
				this.options = res.data.data;
				// 设置默认
				let def = this.options.find(item => item.is_default == 1)
				this.form.account_id = def.id
				this.accountChange(def.id)
			});
		},
		methods: {
			//搜索昵称
			search() {
				if (this.nickname == '') {
					this.$message({
						message: '搜索内容不能为空',
						type: 'warning'
					});
					return
				}
				this.$api.store.getUserInfoByNick({
					nickname: this.nickname
				}).then(res => {
					console.log(res)
					this.producList = res.data

				})
			},
			handleRowChange(data) {
				this.selectId = data.id
				console.log(this.selectId)
			},
			accountChange(id) {
				this.curAccount = this.options.find(item => item.id == id)
			},
			onSubmit() {
				if (this.nickname == '') {
					this.$message({
						message: '搜索内容不能为空',
						type: 'warning'
					});
					return
				}
				
				if (this.selectId == '') {
					this.$message({
						message: '请选择你要提现的用户',
						type: 'warning'
					});
					return
				}
				
				if (this.real_name == '') {
					this.$message({
						message: '真实姓名不能为空',
						type: 'warning'
					});
					return
				}

				const loading = this.$loading();
				this.$api.store.apply({
					user_id: this.selectId,
					real_name: this.real_name,
					withdrawal_money: this.form.withdrawal_money
				}).then(res => {
					loading.close();
					if (res.code == 200) {
						console.log(res.msg)
						// 保存成功
						this.$message.success(`提现成功`);
						this.$router.back();
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close();
					this.$alert(err);
				});


				// this.$refs.form.validate(valid => {
				// 	if (valid) {
				// 		const loading = this.$loading();
				// 		this.$api.store.withdrawal(this.form).then(res => {
				// 			loading.close();
				// 			if (res.code == 200) {
				// 				// 保存成功
				// 				this.$message.success(`提现已申请，请等待审核打款`);
				// 				this.$router.back();
				// 			} else {
				// 				this.$message.error(res.msg);
				// 			}
				// 		}).catch(err => {
				// 			loading.close();
				// 			this.$alert(err);
				// 		});
				// 	} else {
				// 		this.$message.warning(`请检查输入错误`);
				// 		return false;
				// 	}
				// });
			}
		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}
</style>
